import * as React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { AppProps, SeoObject } from 'src/app/types';
import {
  Container,
  Hero,
  Grid,
  Localized,
  SearchBar,
  SignupBox,
  Typography,
} from 'src/app/components';
import { AppPage, CountryProductsContainer } from 'src/app.consumer/components';
import { ProductLoaderContainer } from 'src/app.consumer/container';
// import RootPageMDX from './root.mdx'; // TODO: should be kept as a working example for the future... */}

import { ShopFeaturesContainer } from 'src/app.consumer/container/ShopFeaturesContainer';
import { useStyles } from 'src/app/context';

const seo: SeoObject = {
  title: 'Home',
};

const RootPage = (props: AppProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isWiderMD = useMediaQuery(theme.breakpoints.up('md'));
  const isWiderLG = useMediaQuery(theme.breakpoints.up('lg'));
  const [categoryTypeId, setCategoryTypeId] = React.useState<string>('activity-000');
  return (
    <AppPage seo={seo} {...props} className='h-full'>
      {/* <RootPageMDX {...props} /> // TODO: should be kept as a working example for the future... */}

      <Hero>
        <h1 className='text-3xl md:text-d2 text-neutral-50 mb-6 px-4'>
          <Localized dictKey={'staticLocales:pages./root.hero.title'} />
        </h1>

        <div className='lg:w-10/12 xl:w-7/12'>
          <SearchBar
            currentCategoryTypeId={categoryTypeId}
            setCategoryTypeId={setCategoryTypeId}
          />
        </div>
      </Hero>

      <main className='flex flex-col'>
        <section className='order-2 md:order-first py-9 md:py-16 px-4'>
          <div className='xl:container'>
            <ShopFeaturesContainer />
          </div>
        </section>

        {/* NOTE: CategoryTypeIds carousell - commented out not in layout
        <Grid item xs={12}>
          <CountryProductsContainer
            title={'All categories'}
            description={'Discover what Croatia has to offer'}
            // countryId={'hr'}
            type={'categoryType'}
            // categoryId={'activity-000'}
          />
        </Grid> */}

        <section className='py-9 md:py-10 md:px-4'>
          <div className='xl:container'>
            <ProductLoaderContainer
              title={<Localized dictKey={'shop:/.featured-products.title'} />}
              description={<Localized dictKey={'shop:/.featured-products.description'} />}
            />
          </div>
        </section>

        <section className='py-4 md:py-10 px-4 relative z-0'>
          <div className='xl:container'>
            <CountryProductsContainer
              title={<Localized dictKey={'shop:/.featured-cities.title'} />}
              description={<Localized dictKey={'shop:/.featured-cities.description'} />}
              // cityId={'hr-split-000'}
              // countryId={'hr'}
              type={'city'}
            />
          </div>
        </section>

        <section className='py-4 md:pt-10 md:pb-12 px-4 relative z-0'>
          <div className='xl:container'>
            <CountryProductsContainer
              title={<Localized dictKey={'shop:/.featured-categories.title'} />}
              description={
                <Localized dictKey={'shop:/.featured-categories.description'} />
              }
              // countryId={'hr'}
              type={'category'}
              // categoryId={'activity-000'}
            />
          </div>
        </section>

        {/** TODO: feed in reasonable context => item or fallback mechanism */}

        {/* <SignupBox
          item={{}}
          // cityId={'hr-split-000'}
          // contextId={'product-view'}
          // countryId={'hr'}
          // imageUrl={'/backgroundViva.webp'}
        /> */}
      </main>
    </AppPage>
  );
};

// const data = useStaticQuery(graphql`
//   query {
//     file(relativePath: { eq: "SmallLogo.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1080, quality: 100) {
//           base64
//           aspectRatio
//           src
//           srcSet
//           sizes
//         }
//         fixed(width: 250, height: 250) {
//           base64
//           aspectRatio
//           src
//           srcSet
//         }
//       }
//     }
//   }
// `);

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "../assets/ok.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `

export default RootPage;
